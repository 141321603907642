<template>
  <v-card class="mx-auto elevation-0" height="100%">
    <v-toolbar class="kalan-titles elevation-0" color="primary--text">
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px">
        <v-text-field
          flat
          label="Buscar"
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list
      :loading="loading"
      :getTitle="getTitle"
      :getSubTitle="getSubTitle"
      :getExtra="getExtra"
      :items="items"
      :search="search"
      :actions="listActions"
    >
    </kalan-list>
  </v-card>
</template>

<script>
import ApiService from '@/util/api.service';
import Devices from '@/models/devices.model';
import Crud from '@/util/crud.service';
import DevicesService from '@/util/devices.service';

export default {
  props: {
    model: {
      default: 'devices',
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'actions-button': () => import('@/components/modulo/Actions.vue'),
  },
  data() {
    return {
      items: [],
      groups: {},
      categories: {},
      loading: true,
      search: '',
      attributeFields: [
        'fuelType',
        'kmPerLiter',
        'isDvr',
        'cameras',
        'dvrCode',
        'instantMessenger',
        'phoneList',
        'conductor',
      ],
      actions: [
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
          icon: 'mdi-plus',
          color: 'secondary',
          action: (item) => {
            Crud.create({
              item,
              schema: Devices,
              model: this.model,
              preSave: this.preSave,
            }).then(this.load);
          },
        },
      ],
      listActions: [
        {
          help: 'Geocercas',
          icon: '$geofence-white',
          color: 'orange',
          action: (item) => {
            DevicesService.geofences({
              device: item,
            });
          },
        },
        {
          help: 'Notificaciones',
          icon: 'mdi-bell',
          color: 'green',
          action: (item) => {
            DevicesService.notifications({
              device: item,
            });
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
          icon: 'mdi-pencil',
          color: 'accent',
          action: (item) => {
            Crud.update({
              item,
              schema: Devices,
              model: this.model,
              title: this.getTitle(item),
              preSave: this.preSave,
            }).then(this.load);
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
          icon: 'mdi-eye',
          color: 'mattBlack',
          action: (item) => {
            Crud.read({
              item,
              schema: Devices,
              model: this.model,
              title: this.getTitle(item),
            });
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
          icon: 'mdi-delete',
          color: 'red',
          action: (item) => {
            Crud.delete({
              id: item.id,
              model: this.model,
              title: this.getTitle(item),
            }).then(this.load);
          },
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getTitle(item) {
      let name = 'Desconocido';
      if (this.categories && this.categories[item.category] && this.categories[item.category].name) {
        name = this.categories[item.category].name;
      }
      return `${item.name} | ${name} `;
    },
    getSubTitle(item) {
      let name = 'Desconocido';
      if (this.groups && this.groups[item.groupId] && this.groups[item.groupId].name) {
        name = this.groups[item.groupId].name;
      }
      return `${item.uniqueId} | ${name} `;
    },
    getExtra: () => null,
    preSave(item) {
      const it = Object.assign(item, {});
      this.attributeFields.forEach((field) => {
        it.attributes[field] = it[field];
        delete it[field];
      });
      return it;
    },
    load() {
      this.loading = true;
      ApiService({
        url: '/groups',
        method: 'get',
      }).then((resp) => {
        const grupos = resp.reduce((obj, item) => {
          return {
            ...obj,
            [item.id]: item,
          };
        }, {});
        this.groups = grupos;
      });
      ApiService({
        url: '/v2/device-categories',
        method: 'get',
      })
        .then((resp) => {
          this.categories = resp.reduce((obj, item) => {
            return {
              ...obj,
              [item.id]: item,
            };
          }, {});
        })
        .finally(() => {
          ApiService({
            url: `/${this.model}`,
            method: 'get',
          })
            .then((resp) => {
              this.items = resp.map((it) => {
                this.attributeFields.forEach((field) => {
                  it[field] = it.attributes[field];
                  delete it.attributes[field];
                });
                return it;
              });
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        });
    },
  },
};
</script>
