export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  uniqueId: {
    label: 'Identificador',
    type: String
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'devices',
    autocomplete: false
  },
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true
  },
  model: {
    label: 'Modelo',
    type: String,
    optional: true
  },
  contact: {
    label: 'Contacto',
    type: String,
    optional: true
  },
  category: {
    label: 'Categoría',
    type: String,
    model: 'v2/device-categories',
    autocomplete: false,
  },
  fuelType: {
    label: 'Tipo de combustible',
    type: String,
    options: [{
      id: 'magna',
      name: 'Magna'
    }, {
      id: 'premium',
      name: 'Premium'
    }, {
      id: 'diesel',
      name: 'Diesel'
    }, {
      id: 'gas',
      name: 'Gas'
    }],
    optional: true
  },
  kmPerLiter: {
    label: 'Kilometros por litro',
    type: Number,
    optional: true
  },
  conductor: {
    label: 'Conductor',
    type: String,
    model: 'v2/drivers',
    autocomplete: false,
    optional: true
  },
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true
  },
  instantMessenger: {
    label: 'Mensajería instantanea',
    type: String,
    optional: true,
    options: [{
      id: 'None',
      name: 'Ninguna'
    }, {
      id: 'Whatsapp',
      name: 'Whatsapp'
    }, {
      id: 'Telegram',
      name: 'Telegram'
    }]
  },
  phoneList: {
    label: 'Listado de teléfonos',
    type: Array,
    optional: true,
  },
  'phoneList.$': {
    type: String,
    options: true
  },
  isDvr: {
    label: 'Es DVR?',
    type: Boolean,
    onChange: (val, fields) => {
      fields.dvrCode.setVisibility(val);
      // fields.attributes.setVisibility(val);
    }
  },
  dvrCode: {
    label: 'Codigo DVR',
    type: String,
  },
  cameras: {
    label: 'Camaras',
    type: Array,
    title: (item) => {
      return item.name
    },
    optional: true,
    onChange: (val, field) => {
      // field.numberOfCameras.value = val ? val.length : 0
      field.cameras.value.forEach((attr, i) => {
        attr.name = 'dev' + i
      })
    }
  },
  'cameras.$': {
    type: {
      server: {
        label: 'Servidor',
        type: String,
        model: 'v2/video-servers',
        autocomplete: false,
      },
      name: {
        label: 'Nombre',
        type: String,
        visibility: false,
      },
      ip: {
        label: 'Dirección IP',
        type: String,
      },
      user: {
        label: 'Usuario',
        type: String,
      },
      password: {
        label: 'Password',
        type: String,
      },
      stream: {
        label: 'Stream',
        type: String,
      },
      isPtz: {
        label: 'Es PTZ',
        type: Boolean,
      },
    },
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true,
    visibility: false
  }
};
